@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700;900&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

:root {
    --primary-color: #214559;
    --cognitudo-color-1: #c04333;
    --cognitudo-color-2: #1cafb7;
    --cognitudo-color-3: #ee9938;
    --cognitudo-color-4: #52acc7;
}

.text-primary {
    color: var(--cognitudo-color-1) !important;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary.active, .btn-primary:active {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary:hover,
.btn-dark:hover,
.btn-primary:focus,
.btn-primary:focus-visible{
    color: #fff !important;
    background-color: var(--cognitudo-color-2) !important;
    border-color: var(--cognitudo-color-2) !important;
}

.btn-outline-primary {
    color: var(--primary-color);
    background-color: transparent;
    border-color: var(--primary-color);
}

.btn-outline-primary.active, 
.btn-outline-primary:active {
    color: var(--cognitudo-color-2) !important;
    background-color: transparent !important;
    border-color: var(--primary-color) !important;
}

.btn-outline-primary:hover, 
.btn-outline-primary:focus,
.btn-outline-primary:focus-visible{
    color: var(--cognitudo-color-2) !important;
    background-color: transparent !important;
    border-color: var(--cognitudo-color-2) !important;
    box-shadow: none !important;
}

.btn-link {
    color: var(--primary-color);
    text-decoration: none;
}

.bg-success {
    background-color: var(--cognitudo-color-2) !important;
}

.btn-outline-secondary {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-outline-secondary.active,
.btn-outline-secondary:hover {
    color: white;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.btn-outline-secondary:hover svg {
    fill: white;
}

.btn-check:hover+.btn {
    color: white;
    background-color: var(--cognitudo-color-3);
    border-color: var(--cognitudo-color-3);
}

.btn-check:checked+.btn {
    color: white;
    background-color: var(--cognitudo-color-2);
    border-color: var(--cognitudo-color-2);
}

.tn-outline-danger {
    color: var(--cognitudo-color-1);
    border-color: var(--cognitudo-color-1);
}

.btn-outline-danger.active,
.btn-outline-danger:hover {
    color: white;
    border-color: var(--cognitudo-color-1);
    background-color: var(--cognitudo-color-1);
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--cognitudo-color-2);
}

body {
    background-color: #F3F4F6;
}

pre {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.btn-1 {
    color: white;
    min-width: 160px;
    padding: 10px 30px;
    border-radius: 100px;
    background-color: #ff0000;
    box-shadow: inset 0 45px 0 -4.5px #1e1e2f;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.btn-1:hover {
    box-shadow: inset 0 45px 0 -50px #1e1e2f;
    color: #1e1e2f;
}

.breadcrumb {
    margin-bottom: 0;
}

.send-message-box {
    background: rgba(255, 255, 255, .8);
}

.chat-container {
    padding-bottom: 100px !important;
}

.video-add-buttons {
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
}

.video-add-buttons .video-add-button {
    margin-left: 8px;
    margin-right: 8px;
}

.video-add-buttons .video-add-button:first-child {
    margin-left: 0;
}

.doc-add-buttons {
    display: inline-flex;
    flex-direction: column;
    justify-content: left;
}

.statusSpinner {
    width: 15px;
    height: 15px;
}

.nav-tabs .nav-link {
    color: var(--primary-color);
    font-weight: 600;
}

.nav-tabs .nav-link.active {
    font-weight: bold;
}

.markdown-content p,
.markdown-content ul,
.markdown-content ol {
    margin-bottom: 0;
}

.profile-link a {
    color: var(--primary-color);
}

.instruction:hover {
    cursor: pointer;
    border: 1px solid var(--primary-color);
    background-color: #eeeeee;
}

.accordion-title {
    cursor: pointer;
}

.accordion-title:hover,
.accordion-title.active {
    color: var(--primary-color);
}

.accordian-card {
    border: 1px solid var(--primary-color);
    background-color: var(--bs-light);
}

.chat-message-icons .btn-link {
    color: var(--cognitudo-color-4);
}

.chat-message-icons .btn-link:hover {
    background-color: white;
    color: var(--cognitudo-color-1);
}

.downloadUrl {
    color: var(--primary-color);
    text-decoration: none;
}

.downloadUrl:hover {
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .video-add-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .video-add-buttons .video-add-button {
        margin-left: 0;
        margin-right: 0;
    }

    .doc-add-buttons {
        display: flex;
        justify-content: center;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lang-block {
    cursor: pointer;
    padding-top: 3px;
}

.lang-dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.active-lang {
    text-transform: uppercase;
    font-size: 12px;
}

.stat-chat-message-list-container #cc-message-list-container {
    height: 100%;
}

.stat-chat-offcanvas {
    width: 500px !important;
}

.rmdp-day.rmdp-today span {
    background-color: var(--primary-color) !important;
}

.rmdp-range {
    background-color: var(--cognitudo-color-2) !important;
    box-shadow: 0 0 3px var(--cognitudo-color-2) !important;
}

.rmdp-week-day {
    color: var(--primary-color) !important;
}

.rmdp-left .rmdp-arrow,
.rmdp-right .rmdp-arrow {
    border-color: black !important;
}

.rmdp-arrow-container {
    padding-top: 2px !important;
}

.rmdp-arrow-container:hover {
    background-color: var(--cognitudo-color-2) !important;
}

.full-height-modal .modal-content {
    height: calc(100vh - 2 * var(--bs-modal-margin));
}

.full-height-modal .modal-body {
    display: flex;
    flex-direction: column;
}

.full-height-modal .modal-body .tab-content,
.full-height-modal .modal-body .scroll-container {
    overflow: auto;
    position: relative;
    flex: 1;
}

.full-height-modal .modal-body .tab-content>.tab-pane,
.full-height-modal .modal-body .scroll-container>.scroll-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.merriweather-light {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: normal;
}

.merriweather-regular {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.merriweather-black {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: normal;
}

.merriweather-light-italic {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: italic;
}

.merriweather-regular-italic {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: italic;
}

.merriweather-bold-italic {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: italic;
}

.merriweather-black-italic {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: italic;
}

#assistant-details .assistant-profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#assistant-details img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#assistant-file-tabs .file-name,
#trainingMessage .message-block {
    word-break: break-all;
}

@media screen and (max-width: 992px) {
    .main-tabs {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .top-menu-items {
            margin-left: auto;
            margin-right: auto;
        }
}
