#cc-chat {
    display: flex;
    flex: 1;
}

#cc-hamburger-menu {
    margin-top: 15px;
    margin-left: 5px;
    position: absolute;
    z-index: 1000;
}

#cc-hamburger-menu .dropdown-toggle:after {
    content: none;
}

#cc-hamburger-menu .dropdown-menu {
    padding-left: 5px;
    padding-right: 5px;
}

#cc-hamburger-menu .dropdown-item {
    color: var(--primary-color);
    /* border: 1px solid var(--primary-color); */
    margin-bottom: 10px;
    /* border-radius: 5px; */
    padding-left: 10px;
    padding-right: 10px;
}

#cc-hamburger-menu .dropdown-item:last-child {
    margin-bottom: 0;
}

#cc-toolbar {
    display: flex;
    flex-direction: column;
    padding-top: 20px
}

/* ha a képernyő szélessége kisebb mint 768px*/
@media (max-width: 768px) {
    #cc-toolbar {
        display: none;
    }

    #cc-message-list .cc-message-container .cc-image-container {
        width: 42px !important;
        height: 42px !important;
    }
}

#cc-toolbar Button {
    width: 52px;
    height: 52px;
    margin: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    font-size: 11px;
}

#cc-toolbar .cc-toolbar-btn-active {
    background-color: var(--cognitudo-color-4);
    border-color: var(--cognitudo-color-4);
    color: white;
}

#cc-side-panel:not(:empty) {
    width: 320px;
    transition: width 0.3s ease;
    background: rgba(34, 68, 86, 0.05);
    display: flex;
    position: relative;
    overflow-y: auto;
}

#cc-side-panel-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#cc-side-panel-content {
    padding: 25px 15px;
}

#cc-side-panel-content h3 {
    color: var(--primary-color);
    font-size: 18px;
}

#cc-side-panel-content .tab-content ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#cc-side-panel-content .tab-content ul li {
    border: 1px solid #ccc;
    border-left: 3px solid var(--primary-color);
    border-radius: 5px;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.4);
    color: var(--primary-color);
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

#cc-side-panel-content .tab-content ul li:hover {
    border-color: var(--cognitudo-color-2);
    color: var(--cognitudo-color-2);
}

#cc-side-panel-content .tab-content .file-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-wrap: break-word;
}

#cc-side-panel-content .tab-content .file-title {
    flex: 1;
    width: 85%;
}

#cc-main-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#cc-message-list-panel {
    flex: 1;
    overflow-y: auto;
    position: relative;
}

#cc-message-list-container {
    position: absolute;
}

#cc-side-panel-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 5px 0;
}

#cc-side-panel-head h1 {
    margin: 0;
    padding-top: 2px;
    font-family: "Lora", serif;
    font-size: 22px;
    color: var(--primary-color);
    font-weight: 600;
}

#cc-side-panel-head .close-btn {
    font-size: 22px;
    color: var(--primary-color) !important;
}

#cc-side-panel-content .tab-content .files li .file-summary {
    cursor: pointer;
    max-height: 80px;
    overflow-y: auto;
    display: none;
    background: #fff;
    padding: 5px;
    border: 1px solid var(--side-panel-color);
    border-radius: 5px;
}

#cc-side-panel-content .tab-content .files li .file-summary.active {
    display: block;
}

/*!****************************/
/* *          Common         **/
/* ***************************!*/
/*:root {*/
/*    --side-panel-width: 320px;*/
/*    --chat-border-color: #e8ebee;*/
/*    --main-color: var(--primary-color);*/
/*    --side-panel-background: #f9f9f9;*/
/*    --side-panel-color: #ccc;*/
/*    --side-panel-line-color: var(--primary-color);*/
/*}*/

/*#page {*/
/*    flex: 1;*/
/*}*/

/*#page div:has(#cc-chat-grid),*/
/*section:has(#cc-chat-grid) {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.cc-button {*/
/*    border: 1px solid var(--chat-border-color);*/
/*    border-radius: 0.375em;*/
/*    padding: .75em .75em;*/
/*    color: #fff;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background: var(--main-color);*/
/*}*/

/*!****************************/
/* *          Layout         **/
/* ***************************!*/
/*#cc-side-panel-button-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*!*#cc-side-panel-button-container {*!*/
/*!*    position: absolute;*!*/
/*!*    z-index: 1000;*!*/
/*!*}*!*/

/*!*#cc-chat-grid {*!*/
/*!*    flex: 1;*!*/
/*!*    display: grid;*!*/
/*!*    grid-template-columns: 0 1fr;*!*/
/*!*    grid-template-rows: 1fr auto;*!*/
/*!*    transition: grid-template-columns 500ms;*!*/
/*!*    position: relative;*!*/
/*!*    gap: 10px 20px;*!*/
/*!*    margin: 0 40px*!*/
/*!*}*!*/

/*!*#cc-chat-grid.opened {*!*/
/*!*    grid-template-columns: var(--side-panel-width) 1fr;*!*/
/*!*}*!*/

/*!*#cc-chat-grid > button {*!*/
/*!*    position: absolute;*!*/
/*!*    top: 0;*!*/
/*!*    left: 0;*!*/
/*!*}*!*/

/*!****************************/
/* *          Details        **/
/* ***************************!*/
#cc-assistant-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

#cc-assistant-details .assistant-profile-img {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cc-assistant-details img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#cc-assistant-details .assistant-details {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
    background-color: white;
}

#cc-assistant-details .assistant-details p {
    margin: 0;
}

/* ha a képernyő szélessége kisebb mint 768px */
@media (max-width: 768px) {
    #cc-assistant-details .assistant-title,
    #cc-assistant-details .assistant-subtext {
        text-align: center !important;
    }
}


/*!****************************/
/* *          Form           **/
/* ***************************!*/
/*#cc-form {*/
/*    display: flex;*/
/*    gap: 0.5em;*/
/*    align-items: flex-end;*/
/*}*/

/*#cc-form form {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: flex-end;*/
/*    gap: 0.5em;*/
/*    flex: 1;*/
/*}*/

/*#cc-form form textarea {*/
/*    display: block;*/
/*    flex: 1;*/
/*    padding: .375em .75em;*/
/*    font-size: 1em;*/
/*    font-weight: 400;*/
/*    line-height: 1.5;*/
/*    color: #212529;*/
/*    appearance: none;*/
/*    background-color: #fff;*/
/*    background-clip: padding-box;*/
/*    border: 1px solid #dee2e6;*/
/*    border-radius: 0.375em;*/
/*    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
/*}*/

/*#cc-form form button.submit {*/
/*    background: #212529;*/
/*}*/

/*#cc-form form button.submit svg {*/
/*    display: inline;*/
/*}*/

/*#cc-form form button.submit span {*/
/*    display: none;*/
/*}*/

/*#cc-form form button.submit:disabled svg {*/
/*    display: none;*/
/*}*/

/*#cc-form form button.submit:disabled span {*/
/*    display: inline;*/
/*}*/

/*#cc-form form .spinner-border-sm {*/
/*    --bs-spinner-width: 1rem;*/
/*    --bs-spinner-height: 1rem;*/
/*    --bs-spinner-border-width: 0.2em;*/
/*}*/

/*#cc-form form .spinner-border {*/
/*    --bs-spinner-width: 1rem;*/
/*    --bs-spinner-height: 1rem;*/
/*    --bs-spinner-vertical-align: -0.125em;*/
/*    --bs-spinner-border-width: 0.25em;*/
/*    --bs-spinner-animation-speed: 0.75s;*/
/*    --bs-spinner-animation-name: spinner-border;*/
/*    border: var(--bs-spinner-border-width) solid currentcolor;*/
/*    border-right-color: transparent;*/
/*}*/

/*#cc-form form .spinner-border,*/
/*#cc-form form .spinner-grow {*/
/*    display: inline-block;*/
/*    width: var(--bs-spinner-width);*/
/*    height: var(--bs-spinner-height);*/
/*    vertical-align: var(--bs-spinner-vertical-align);*/
/*    border-radius: 50%;*/
/*    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);*/
/*}*/

/*@keyframes spinner-border {*/
/*    to {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

/*#cc-form .audio-button {*/
/*    background: var(--main-color);*/
/*}*/

/*#cc-form .audio-button svg {*/
/*    display: none;*/
/*}*/

/*#cc-form .audio-button.disabled svg.disabled {*/
/*    display: inline;*/
/*}*/

/*#cc-form .audio-button.microphone svg.microphone {*/
/*    display: inline;*/
/*}*/

/*#cc-form .audio-button.sending svg.sending {*/
/*    display: inline;*/
/*}*/

/*#cc-form .audio-button.speaker svg.speaker {*/
/*    display: inline;*/
/*}*/


/*!****************************/
/* *     Message list        **/
/* ***************************!*/
#cc-message-list-container {
    overflow-y: auto;
    position: relative;
}

#cc-message-list-content {
    position: absolute;
    width: 100%;
}

#cc-message-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#cc-message-list .cc-message-container {
    display: flex;
    gap: 20px;
}

#cc-message-list .cc-message-container.user {
    flex-direction: row-reverse;
}

#cc-message-list .cc-message-container .cc-image-container {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cc-message-list .cc-message-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#cc-message-list .cc-message-container .cc-message {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: .5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    padding: 10px 15px;
    background-color: #fff;
}

#cc-message-list .cc-message-container.user .cc-message {
    background: var(--cognitudo-color-4);
    color: #fff;
}

#cc-instructions {
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
}

#cc-instructions .instruction {
    border: 1px solid var(--cognitudo-color-4);
    border-radius: 0.375em;
    padding: .375em .75em;
    cursor: pointer;
}

#cc-instructions .instruction:hover {
    border: 1px solid var(--main-color);
    color: var(--main-color);
}

.send-message-box .btn-primary,
.send-message-box .btn-dark {
    background-color: var(--cognitudo-color-4);
    border-color: var(--cognitudo-color-4);
}

.send-message-box .btn-primary:hover,
.send-message-box .btn-dark:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

/*!****************************/
/* *       Side panel        **/
/* ***************************!*/
/*#cc-side-panel {*/
/*    grid-row: span 2;*/
/*    overflow-x: hidden;*/
/*    overflow-y: auto;*/
/*    margin-top: 4em;*/
/*    border-radius: .5rem;*/
/*    position: relative;*/
/*}*/

/*#cc-side-panel-content {*/
/*    padding: 10px;*/
/*    width: var(--side-panel-width);*/
/*    background: var(--side-panel-background);*/
/*    position: absolute;*/
/*}*/

/*#cc-side-panel-content .tab-links {*/
/*    position: relative;*/
/*    padding: 0 5px;*/
/*}*/

/*#cc-side-panel-content .tab-links:before {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    content: '';*/
/*    border-bottom: 1px solid var(--side-panel-color);*/
/*}*/

/*#cc-side-panel-content .tab-links span {*/
/*    background: var(--side-panel-background);*/
/*    border-bottom: 1px solid var(--side-panel-color);*/
/*    display: inline-block;*/
/*    padding: 5px 10px;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*}*/

/*#cc-side-panel-content .tab-links span.active {*/
/*    border: 1px solid var(--side-panel-color);*/
/*    border-bottom-color: var(--side-panel-background);*/
/*    border-radius: 5px 5px 0 0;*/
/*}*/

/*#cc-side-panel-content .tab-content > div {*/
/*    display: none;*/
/*}*/

/*#cc-side-panel-content .tab-content > div.active {*/
/*    display: block;*/
/*}*/

/*#cc-side-panel-content .tab-content h3 {*/
/*    margin-top: 20px;*/
/*    margin-bottom: 10px;*/
/*    border-bottom: 2px solid var(--side-panel-color);*/
/*    padding-bottom: 5px;*/
/*    font-size: 18px;*/
/*}*/

/*#cc-side-panel-content .tab-content .threads > h3:not(:first-child) {*/
/*    margin-top: 30px;*/
/*}*/

/*#cc-side-panel-content .tab-content ul {*/
/*    list-style-type: none;*/
/*    padding-left: 0;*/
/*    margin-bottom: 20px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 5px;*/
/*}*/

/*#cc-side-panel-content .tab-content .threads li {*/
/*    padding: 5px 10px;*/
/*    border-left: 4px solid var(--side-panel-color);*/
/*    border-radius: 4px;*/
/*    position: relative;*/
/*    font-size: .9em;*/
/*    cursor: pointer;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*    box-sizing: content-box;*/
/*}*/

/*#cc-side-panel-content .tab-content .threads li:hover {*/
/*    color: var(--side-panel-line-color);*/
/*    border-color: var(--side-panel-line-color);*/
/*}*/

/*#cc-side-panel-content .tab-content .threads li:hover::after {*/
/*    right: 10px;*/
/*    left: 10px;*/
/*}*/

/*#cc-side-panel-content .tab-content .threads li::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    right: 50%;*/
/*    bottom: 0;*/
/*    height: 2px;*/
/*    background-color: var(--side-panel-line-color);*/
/*    transition: right 0.3s ease, left 0.3s ease;*/
/*}*/

/*#cc-side-panel-content .tab-content .files {*/
/*    padding-top: 10px;*/
/*}*/

/*#cc-side-panel-content .tab-content .files li {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 5px 10px;*/
/*    border-left: 4px solid var(--side-panel-color);*/
/*    border-radius: 4px;*/
/*    position: relative;*/
/*    font-size: .9em;*/
/*}*/

/*#cc-side-panel-content .tab-content .files li .file-details {*/
/*    display: flex;*/
/*    word-wrap: break-word;*/
/*}*/

/*#cc-side-panel-content .tab-content .files li .file-title {*/
/*    flex: 1;*/
/*    cursor: pointer;*/
/*    width: 85%;*/
/*}*/

/*#cc-side-panel-content .tab-content .files li .file-summary {*/
/*    cursor: pointer;*/
/*    max-height: 80px;*/
/*    overflow-y: auto;*/
/*    display: none;*/
/*    background: #fff;*/
/*    padding: 5px;*/
/*    border: 1px solid var(--side-panel-color);*/
/*    border-radius: 5px;*/
/*}*/

/*#cc-side-panel-content .tab-content .files li .file-summary.active {*/
/*    display: block;*/
/*}*/